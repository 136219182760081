import { getAction, postAction } from '@/utils/http.js'//引入封装的get post方法

//关于我们
const aboutUs = (params)=>getAction("/api/official/website/about_us/about_us",params);
//新闻动态
const news = (params)=>getAction("/api/official/website/about_us/news",params);
//新闻详情
const newItem = (id)=>getAction("/api/official/website/common/getArticleById?id="+id);
//荣誉与认证
const certification = (params)=>getAction("/api/official/website/about_us/certification",params);
//法律声明
const statement = (params)=>getAction("/api/official/website/about_us/statement",params);

export {
    aboutUs,
    news,
    newItem,
    certification,
    statement,
}