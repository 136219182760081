<template>
    <div class="viewContainer" v-loading="loading">
        <!-- 内容 -->
        <div v-if="$route.name =='News'">
            <div class="topTitle">新闻动态</div>
            <hr  class="hr" >
            <div class="content">
                <div v-for="(item, index) in content" :key="index">
                    <div class="shortTitle">{{item&&item.name?item.name:''}}</div>
                    <div v-if="item&&item.articlesVOList">
                        <div class="newItem" @click="gotoNewItem(t)" v-for="(t,i) in item.articlesVOList" :key="i">{{t.title}}</div>
                    </div>
                    
                </div>
            </div>
        </div>
        
        <!-- <div class="content" v-else v-html="content.content"></div> -->
        <article-com v-else ref="articleCom" ></article-com>
    </div>
</template>
<script>
import { aboutUs, news, newItem, certification, statement,} from '@/services/aboutUs.js'
import articleCom from '@/pages/components/article'
export default {
  name: "company",
  components:{
    articleCom
  },
  data() {
    return {
        routeName:'',
        loading:false,
        content:{},
        currentNewId:'',
    };
  },
  computed: {
  },
  created() {
  },
  destroyed() {
  },
  watch: {
  },
  mounted() {
    console.log('mounted---')
  },
  activated(){
    console.log('activated---')
    console.log('this.$route---',this.$route)
    this.currentNewId = ''
    if(this.$route.name == 'AboutUs'){//页面参数
        this.getAboutUs()
    }else if(this.$route.name =='News'){
        this.getNews()
    }else if(this.$route.name =='NewItem'){
        this.currentNewId = this.$route.params.id
        this.getNewItem()
    }else if(this.$route.name =='Certification'){
        this.getCertification()
    }else if(this.$route.name == 'Statement'){
        this.getStatement()
    }
  },
  methods:{

    //关于我们
    getAboutUs(){
        this.content = {}
        this.loading = true;
        aboutUs().then(res => {
            if(res.data.code===0 && res.data.data){
                this.content = res.data.data
                document.title = this.content.title+' - 南京沁恒微电子股份有限公司'
                this.$refs.articleCom.init( this.content)
            }else{
                this.$message.error(res.data.message);
            }
            this.loading = false;
        });
    },
    //新闻动态
    getNews(){
        this.content = {}
        this.loading = true;
        news().then(res => {
            if(res.data.code===0 && res.data.data){
                this.content = res.data.data
                console.log('新闻动态', this.content)
            }else{
                this.$message.error(res.data.message);
            }
            this.loading = false;
        });
    },
    //获取新闻详情
    getNewItem(){
        this.content = {}
        this.loading = true;
        newItem(this.currentNewId+".html").then(res => {
            if(res.data.code===0 && res.data.data){
                console.log('新闻详情----',res.data.data)
                this.content = res.data.data
                this.$nextTick(()=>{
                    this.$refs.articleCom.init( this.content, true)
                })
                
                document.title = this.content.shortTitle+' - 南京沁恒微电子股份有限公司'
            }else{
                this.$message.error(res.data.message);
            }
            this.loading = false;
        });
    },
    //点击具体新闻
    gotoNewItem(t){
        this.$emit('currentNew',t)//将当前点击新闻传递父组件
        this.$router.push({ path: '/news/'+t.id+'.html',})
    },
    //荣誉与认证
    getCertification(){
        this.content = {}
        this.loading = true;
        certification().then(res => {
            if(res.data.code===0 && res.data.data){
                this.content = res.data.data
                document.title = this.content.title+' - 南京沁恒微电子股份有限公司'
                this.$refs.articleCom.init( this.content)
            }else{
                this.$message.error(res.data.message);
            }
            this.loading = false;
        });
    },
    //法律声明
    getStatement(){
        this.content = {}
        this.loading = true;
        statement().then(res => {
            if(res.data.code===0 && res.data.data){
                this.content = res.data.data
                document.title = this.content.title+' - 南京沁恒微电子股份有限公司'
                this.$refs.articleCom.init( this.content)
            }else{
                this.$message.error(res.data.message);
            }
            this.loading = false;
        });
    },
    
  }
};
</script>
<style scoped lang="less">
 .viewContainer{
    width: 100%;
    text-align: left;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;

    .topTitle{
        font-size: 22px;
        color: #002c85;
        font-weight: 600;
        letter-spacing: 0;
        margin-bottom: 20px;
    }
    .hr{
        border-top: 1px solid #cbd0d4;
        margin: 25px 0px;
    }
    .content{
        font-size: 15px !important;
    }
    // .content /deep/span{
    //     font-size: 15px !important;
    //     font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
    // }
    // .content /deep/h4{
    //     font-size: 16px !important;
    //     font-weight: 700;
    //     color: #002c85 !important;
    //     font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
    // }
    // .content /deep/li{
    //     margin-bottom: 8px;
    //     font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
    // }
    // .content /deep/strong{
    //     color: rgb(0, 176, 240);
    //     font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
    // }
    .content .shortTitle{
        font-size: 16px;
        color: #1482DC;
        font-weight: 700;
        // margin-bottom: 10px;
    }
    .content .newItem{
        margin: 8px 0px;
        cursor: pointer;
    }
    .content .newItem:hover{
        color: #1482DC;
    }

 }
 
@media screen and (max-width: 370px) {
   
}
</style>