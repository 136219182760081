<template>
    <div class="article">
        <div class="topTitle" v-if="!titleHide">{{articalObj.shortTitle}}</div>
        <hr  class="hr" v-if="!titleHide">
        <div v-if="articalObj.contentType&&articalObj.contentType=='markdown'" class="content">
           <v-md-editor v-model="articalObj.content"  mode="preview" />
        </div>
        <div v-else class="content"  v-html="articalObj.content"></div>
    </div>
</template>
<script>
import VueMarkdown from 'vue-markdown'
import VueMarkdownEditor, { xss } from '@kangc/v-md-editor'
export default {
  name: "article",
  components:{
    VueMarkdown,
    VueMarkdownEditor,
  },
  props:[
  ],
  data() {
    return {
        windowWidth:window.innerWidth,
        isMoPhone:false,
        articalObj:{},
        titleHide:false,
    };
  },
  computed: {
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (newWidth < 992) {
        this.isMoPhone = true
      }else{
        this.isMoPhone = false
      }
    },
  },
  mounted() {
  },
  activated(){
  },
  methods:{
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    init(articalObj, titleHide){
       this.articalObj = articalObj
       this.titleHide = titleHide?true:false
    },
   
  }
};
</script>
<style scoped lang="less">

 .article {
    width: 100%;
    margin: 0 auto;
    // box-shadow: 6px 6px 12px rgb(0 0 0%);
    border-radius: 4px;
    text-align: left;
    .topTitle{
        font-size: 22px;
        color: #002c85;
        font-weight: 600;
        letter-spacing: 0;
        margin-bottom: 20px;
    }
    .hr{
        border-top: 1px solid #cbd0d4;
        margin: 25px 0px;
    }
    .content{
        font-size: 15px !important;
    }
    .content /deep/span{
        font-size: 15px !important;
        font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
    }
    .content /deep/li{
        margin-bottom: 8px;
        font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
    }
    .content /deep/strong{
        color: rgb(0, 176, 240);
        font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
    }
    .content /deep/.article-content h4, .article-content h4 span {
        font-size: 16px !important;
        font-weight: 700;
        font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
        color: #2e74b5 !important;
    }
    .content /deep/.article-content {
        font-size: 15px !important;
        font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
    }
    .content /deep/img{
        max-width: 100% !important;
        height: auto !important;
        display: block;
        margin: 10px auto 15px auto;
    }
    .content /deep/.article-content img {
        max-width: 100% !important;
        height: auto !important;
    }

    // 产品中心文章
.content /deep/a{
    color: #0070c0 !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.content /deep/ h4 span {
    font-size: 20px !important;
    font-weight: 600;
    color: #002c85 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.content /deep/ h5 span, 
.content /deep/ h5  {
    font-weight: 700;
    color: #00B0F0 !important;
    padding-left: 5px;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
    font-size: 16px !important;
    margin: 0px;
}
.content /deep/ span a {
    font-size: 15px !important;
}
// .content /deep/ p span {
//     font-size: 15px !important;
//     color: #000 !important;
//     font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
// }

.content /deep/ ul li span {
    font-size: 15px !important;
    color: #000 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.content /deep/ ol li span {
    font-size: 15px !important;
    color: #000 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.content /deep/ ol li a span {
    font-size: 15px !important;
    color: #0070c0 !important;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;
}
.content /deep/ul,
.content /deep/ol
{
    margin: 0px !important;
}
.content /deep/ h4{
    font-size: 20px;
    color: #002c85;
    font-weight: 600;
    letter-spacing: 0px;
    margin-bottom: 10px;
    margin-top: 10px;
}
    
 }


</style>